import React, { useState } from 'react';
import PropTypes from 'prop-types';
import telegramImage from './telegram.png';
import instagramImage from './instagram.png';
import viberImage from './viber.png';
import './index.scss';

const OpenButton = (
  <svg
    viewBox="0 -256 1850 1850"
    xmlns="http://www.w3.org/2000/svg"
    style={
      {
        width: '100%',
        height: '100%',
        fill: 'rgb(255, 255, 255)',
        stroke: 'none',
      }
    }
  >
    <path
      transform="matrix(0.71186441,0,0,-0.71186441,288.54237,1093.9482)"
      d="M 704,1152 Q 551,1152 418,1100 285,1048 206.5,959 128,870 128,768 q 0,-82 53,-158 53,-76 149,-132 l 97,-56 -35,-84 q 34,20 62,39 l 44,31 53,-10 q 78,-14 153,-14 153,0 286,52 133,52 211.5,141 78.5,89 78.5,191 0,102 -78.5,191 -78.5,89 -211.5,141 -133,52 -286,52 z m 0,128 q 191,0 353.5,-68.5 Q 1220,1143 1314,1025 1408,907 1408,768 1408,629 1314,511 1220,393 1057.5,324.5 895,256 704,256 618,256 528,272 404,184 250,144 214,135 164,128 h -3 q -11,0 -20.5,8 -9.5,8 -11.5,21 -1,3 -1,6.5 0,3.5 0.5,6.5 0.5,3 2,6 l 2.5,5 q 0,0 3.5,5.5 3.5,5.5 4,5 0.5,-0.5 4.5,5 4,5.5 4,4.5 5,6 23,25 18,19 26,29.5 8,10.5 22.5,29 Q 235,303 245.5,323 256,343 266,367 142,439 71,544 0,649 0,768 0,907 94,1025 188,1143 350.5,1211.5 513,1280 704,1280 Z M 1526,111 q 10,-24 20.5,-44 10.5,-20 25,-38.5 14.5,-18.5 22.5,-29 8,-10.5 26,-29.5 18,-19 23,-25 1,-1 4,-4.5 3,-3.5 4.5,-5 1.5,-1.5 4,-5 2.5,-3.5 3.5,-5.5 l 2.5,-5 q 0,0 2,-6 2,-6 0.5,-6.5 -1.5,-0.5 -1,-6.5 -3,-14 -13,-22 -10,-8 -22,-7 -50,7 -86,16 Q 1388,-72 1264,16 1174,0 1088,0 817,0 616,132 q 58,-4 88,-4 161,0 309,45 148,45 264,129 125,92 192,212 67,120 67,254 0,77 -23,152 129,-71 204,-178 75,-107 75,-230 0,-120 -71,-224.5 Q 1650,183 1526,111 Z"
    />
  </svg>
);

const CloseButton = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-9 -9 41 41"
    style={
      {
        width: '100%',
        height: '100%',
        fill: 'rgb(255, 255, 255)',
        stroke: 'none',
      }
    }
  >
    <path d=" M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
);

/**
 * @param telegram {String}
 * @param instagram {String}
 * @param viber {String}
 */
function Contact({
  telegram,
  instagram,
  viber,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const contactListClasses = ['contact-list'];
  const contactToggleClasses = ['contact-toggle'];

  if (isOpen) {
    contactListClasses.push('contact-list--visible');
    contactToggleClasses.push('contact-toggle--on');
  }

  const open = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <div className="contact">
      <div className={contactListClasses.join(' ')}>
        <div className="contact-list-item">
          <a href={instagram}><img src={instagramImage} alt="instagram" /></a>
        </div>
        <div className="contact-list-item">
          <a href={viber}><img src={viberImage} alt="viber" /></a>
        </div>
        <div className="contact-list-item">
          <a href={telegram}><img src={telegramImage} alt="telegram" /></a>
        </div>
      </div>
      <div
        role="button"
        tabIndex="0"
        className={contactToggleClasses.join(' ')}
        onMouseEnter={open}
        onMouseDown={toggle}
        onKeyDown={toggle}
        onTouchStart={toggle}
        onTouchEnd={preventDefault}
      >
        {isOpen ? CloseButton : OpenButton}
      </div>
    </div>
  );
}

Contact.displayName = 'Contact';

Contact.propTypes = {
  telegram: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  viber: PropTypes.string.isRequired,
};

Contact.defaultProps = {};

export default Contact;
