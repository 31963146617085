import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import './index.scss';

const digConfiguration = (path, data) => path.reduce((accumulator, step) => accumulator.state[step], data);

const commitSurvey = (endpoint, csrfToken, value) => {
  fetch(endpoint, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    method: 'PATCH',
    body: JSON.stringify({
      survey: value,
    }),
  });
};

const orderSurveyPass = () => Cookies.set('order-survey-status', 'passed', { expires: 14 });
const isOrderSurveyPassed = () => Cookies.get('order-survey-status') === 'passed';

function OrderSurvey({
  configuration,
  updateEndpoint,
  csrfToken,
  onSurveyPassed,
}) {
  const [path, setPath] = useState([]);

  if (isOrderSurveyPassed()) {
    onSurveyPassed();
    return null;
  }

  const actionChoose = (event) => {
    const option = event.target.getAttribute('data-option');
    const newPath = [...path, option];

    if ('state' in digConfiguration(newPath, configuration)) {
      setPath(newPath);
    } else {
      commitSurvey(updateEndpoint, csrfToken, newPath.join('_'));
      orderSurveyPass();
      onSurveyPassed();
    }
  };

  const currentConfiguration = digConfiguration(path, configuration);
  const options = Object.entries(currentConfiguration.state).map(([key, option]) => (
    <button type="button" className="order-survey-option" key={key} data-option={key} onClick={actionChoose}>
      {option.name}
    </button>
  ));

  return (
    <div className="order-survey">
      {options}
    </div>
  );
}

OrderSurvey.displayName = 'OrderSurveyOptions';

OrderSurvey.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  configuration: PropTypes.object.isRequired,
  updateEndpoint: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  onSurveyPassed: PropTypes.func.isRequired,
};

OrderSurvey.defaultProps = {
};

export default OrderSurvey;
