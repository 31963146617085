const initVideoPlayer = () => {
  const videoPlayer = document.getElementById('video-player');
  if (!videoPlayer) return;

  const video = videoPlayer.querySelector('video');
  const toggleButton = videoPlayer.querySelector('[data-video-player-toggle]');

  toggleButton.addEventListener('click', () => {
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  });

  video.addEventListener('play', () => {
    videoPlayer.classList.add('playing');
    videoPlayer.classList.remove('paused');
  });

  video.addEventListener('pause', () => {
    videoPlayer.classList.add('paused');
    videoPlayer.classList.remove('playing');
  });
};

export default initVideoPlayer;
