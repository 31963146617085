import React, { useState } from 'react';
import { useInterval } from 'react-use';
import PropTypes from 'prop-types';
import './index.scss';

const INTERVAL = 500;

const leftUntil = (deadlineTimestamp) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const leftSeconds = deadlineTimestamp - currentTimestamp;

  return leftSeconds < 0 ? 0 : leftSeconds;
};

const zeroPad = (num, places) => String(num).padStart(places, '0');

/**
 * @param label {String}
 * @param deadlineTimestamp {number}
 */
function Countdown({ label, deadlineTimestamp }) {
  const [state, setState] = useState(leftUntil(deadlineTimestamp));

  useInterval(() => {
    setState(leftUntil(deadlineTimestamp));
  }, INTERVAL);

  const second = state % 60;
  const minutes = Math.floor(state / 60);
  const minute = minutes % 60;
  const hours = Math.floor(minutes / 60);

  return (
    <>
      {label}
      <span>
        {zeroPad(hours, 2)}
        :
        {zeroPad(minute, 2)}
        :
        {zeroPad(second, 2)}
      </span>
    </>
  );
}

Countdown.displayName = 'Countdown';

Countdown.propTypes = {
  label: PropTypes.string.isRequired,
  deadlineTimestamp: PropTypes.number.isRequired,
};

Countdown.defaultProps = {
};

export default Countdown;
