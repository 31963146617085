import React from 'react';
import PropTypes from 'prop-types';

// Just pure file input
const Fallback = (props) => {
  const {
    accept,
    fileName,
    cacheName,
    cache,
    label,
  } = props;

  return (
    <label>
      {label}
      <input type="file" accept={accept} name={fileName} />
      {cache && <input type="hidden" name={cacheName} value={cache} />}
    </label>
  );
};

Fallback.displayName = 'Fallback';

Fallback.propTypes = {
  accept: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  cacheName: PropTypes.string.isRequired,
  cache: PropTypes.string,
  label: PropTypes.string.isRequired,
};

Fallback.defaultProps = {
  accept: '*/*',
};

export default Fallback;
