import React from 'react';
import PropTypes from 'prop-types';

const RADIUS = 50;
const CIRCUMFERENCE = RADIUS * 2 * Math.PI;

function Progress(props) {
  const { progress } = props;
  const offset = CIRCUMFERENCE * (1 - progress / 100);

  return (
    <div className="order-progress">
      <span className="order-progress-checker order-progress-checker-animate">
        <span className="order-progress-checker-count">
          {progress}
          %
        </span>
        <svg className="order-progress-checker-wrapper">
          <circle
            className="order-progress-checker-circle--empty"
            cx="62"
            cy="62"
            r={RADIUS}
          />
          <circle
            className="order-progress-checker-circle--full"
            cx="62"
            cy="62"
            r={RADIUS}
            style={{
              transition: 'all 0.3s',
              strokeDasharray: CIRCUMFERENCE,
              strokeDashoffset: offset,
            }}
          />
        </svg>
      </span>
    </div>
  );
}

Progress.displayName = 'Progress';

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
};

Progress.defaultProps = {};

export default Progress;
