const initAnchorScroll = () => {
  document.querySelectorAll('a[href^="#"]')
    .forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        const targetSelector = e.currentTarget.getAttribute('href');
        if (targetSelector === null) return;
        if (targetSelector.charAt(0) !== '#') return;
        if (targetSelector.length === 1) return;

        const targetElement = document.querySelector(targetSelector);
        if (!targetElement) return;

        targetElement.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
};

export default initAnchorScroll;
