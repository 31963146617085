// Social Sharing js helper
const SocialShare = {
  openUrl(url, width = 640, height = 480) {
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height * 0.3) - (height / 2);
    const opt = `width=${width},height=${height},left=${left},top=${top},menubar=no,status=no,location=no`;
    window.open(url, 'popup', opt);
  },
  share(el) {
    const service = el.getAttribute('data-service');
    const title = encodeURIComponent(el.getAttribute('data-title') || '');
    const img = encodeURIComponent(el.getAttribute('data-img') || '');
    const description = encodeURIComponent(el.getAttribute('data-description') || '');

    let url = el.getAttribute('data-url') || window.location.href;
    // drop url's schema
    url = url.replace(/(^\w+:|^)\/\//, '');
    url = encodeURIComponent(url);

    switch (service) {
      case 'email':
        window.location.href = `mailto:?to=&subject=${title}&body=${url}`;
        break;
      case 'twitter':
        SocialShare.openUrl(`https://twitter.com/intent/tweet?url=${url}&text=${title}`, 650, 300);
        break;
      case 'douban':
        SocialShare.openUrl(`https://shuo.douban.com/!service/share?href=${url}&name=${title}&image=${img}&sel=${description}`, 770, 470);
        break;
      case 'facebook':
        SocialShare.openUrl(`https://www.facebook.com/sharer/sharer.php?u=${url.replace(/(^\w+:|^)\/\//, '')}&display=popup&quote=${description}`, 555, 400);
        break;
      case 'google_plus':
        SocialShare.openUrl(`https://plus.google.com/share?url=${url}`);
        break;
      case 'google_bookmark':
        SocialShare.openUrl(`https://www.google.com/bookmarks/mark?op=edit&output=popup&bkmk=${url}&title=${title}`);
        break;
      case 'delicious':
        SocialShare.openUrl(`https://del.icio.us/save?url=${url}&title=${title}&jump=yes&pic=${img}`);
        break;
      case 'pinterest':
        SocialShare.openUrl(`https://www.pinterest.com/pin/create/button/?url=${url}&media=${img}&description=${title}`);
        break;
      case 'linkedin':
        SocialShare.openUrl(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}`);
        break;
      case 'xing':
        SocialShare.openUrl(`https://www.xing.com/spi/shares/new?url=${url}`);
        break;
      case 'vkontakte':
        SocialShare.openUrl(`https://vk.com/share.php?url=${url}&title=${title}&image=${img}`);
        break;
      case 'odnoklassniki':
        SocialShare.openUrl(`https://connect.ok.ru/offer?url=${url}&title=${title}&description=${description}&imageUrl=${img}`);
        break;
      case 'telegram':
        SocialShare.openUrl(`https://telegram.me/share/url?text=${title}&url=${url}`);
        break;
      default:
    }
  },
};

const initSocialShare = () => {
  window.SocialShare = SocialShare;
};

export default initSocialShare;
