import ahoy from 'ahoy.js';

/**
 * Init analytics
 */
export function initAnalyticsObservable() {
  if (typeof window.IntersectionObserver !== 'function') {
    ahoy.track('observe:init_failure');
    return;
  }

  ahoy.track('observe:init_success');

  const { IntersectionObserver } = window;
  const viewportHeight = window.innerHeight;
  const observableElements = Array.from(document.querySelectorAll('[data-observable]'));
  const observableElementsWithHeight = observableElements.map(
    (element) => [element, element.getBoundingClientRect().height],
  );

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        ahoy.track(`observe:${entry.target.getAttribute('data-observable')}`);
      }
    });
  };

  observableElementsWithHeight.forEach(([element, height]) => {
    const threshold = viewportHeight >= height ? 1.0 : Math.floor((viewportHeight * 10) / height) / 10;

    new IntersectionObserver(callback, {
      threshold,
    }).observe(element);
  });
}

export function initAnalyticsTrackable() {
  const clickTrackableElements = Array.from(document.querySelectorAll('[data-click-trackable]'));

  clickTrackableElements.forEach((element) => {
    element.addEventListener('click', () => {
      ahoy.track(`trackable:click_${element.getAttribute('data-click-trackable')}`);
    });
  });
}
