import React, { createElement } from 'react';
import { createRoot } from 'react-dom/client';

/**
 * Adds support of render callback
 *
 * @param component
 * @param callback
 * @returns {JSX.Element}
 */
function withCallback(component, callback = undefined) {
  return <div ref={callback}>{component}</div>;
}

/**
 * Simple init for react components
 *
 * @param className
 * @param reactComponent
 * @param reactProps
 */
const initReact = (className, reactComponent, reactProps = {}) => {
  const componentNode = window.document.querySelector(`.component.${className}`);

  if (!componentNode) {
    return;
  }

  const componentLoaderNode = componentNode.querySelector('.component-loader');
  const componentContainerNode = componentNode.querySelector('.component-container');

  if (!componentLoaderNode || !componentContainerNode) {
    throw new Error(`Component ${className} has invalid html structure`);
  }

  const rawProps = componentNode.getAttribute('data-props');

  let props;
  if (!rawProps) {
    props = {};
  } else {
    try {
      props = JSON.parse(rawProps);
    } catch (_) {
      throw new Error(`Component ${className} has invalid props format`);
    }
  }

  props = { ...props, ...reactProps };

  const reactRoot = createRoot(componentContainerNode);
  const reactElement = createElement(reactComponent, props);
  const reactElementWithCallback = withCallback(reactElement, () => {
    componentLoaderNode.classList.add('hidden');
  });

  reactRoot.render(reactElementWithCallback);
};

/**
 * Init temporary react component in temporary div
 *
 * @param reactComponent
 * @param reactProps
 */
const initReactTemporary = (reactComponent, reactProps = {}) => {
  const temporaryComponentNode = document.createElement('div');
  document.body.appendChild(temporaryComponentNode);

  const reactRoot = createRoot(temporaryComponentNode);
  const reactElement = createElement(reactComponent, {
    ...reactProps,
    unmount: () => {
      reactRoot.unmount();
      temporaryComponentNode.remove();
    },
  });

  reactRoot.render(reactElement);
};

export default {
  initReact,
  initReactTemporary,
};
