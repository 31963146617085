import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from 'react-use';
import Progress from './Progress';
import withAnimatedNumber from './lib/decorators/withAnimatedNumber';
import './index.scss';

// Delay for order status checking, in milliseconds
const CHECKER_DELAY = 2000;

/**
 * Animate progress change with 100 ms delay
 */
const AnimatedProgress = withAnimatedNumber(
  Progress,
  'progress',
  0,
  0,
  CHECKER_DELAY,
);

/**
 * Dispatcher actions
 */
const SET_PROGRESS = Object.freeze('SET_PROGRESS');

const initState = (progress) => ({
  progress,
  duration: 2 * CHECKER_DELAY,
});

const reduceState = (state, {
  type,
  payload,
}) => {
  switch (type) {
    case SET_PROGRESS:
      return {
        ...state,
        progress: payload.progress,
        duration: payload.duration,
      };
    default:
      return state;
  }
};

/**
 * Normalizes progress to [5, 100] range
 *
 * @param progress {number}
 * @returns {number}
 */
const normalizeProgress = (progress) => 5 + ((95 * progress) / 100);

function ProgressChecker(props) {
  const {
    progress,
    statusEndpoint,
  } = props;
  const [state, dispatchState] = useReducer(reduceState, normalizeProgress(progress), initState);

  const checkStatus = () => {
    // Check status
    fetch(statusEndpoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((result) => {
        if (result.status === 'done') {
          window.location.reload();
          return;
        }

        dispatchState({
          type: SET_PROGRESS,
          payload: {
            progress: normalizeProgress(result.progress),
            duration: 2 * CHECKER_DELAY,
          },
        });
      });
  };

  // Check every CHECKER_DELAY order status and reload page if it done or update progress :)
  useInterval(checkStatus, CHECKER_DELAY);

  return (<AnimatedProgress progress={state.progress} duration={state.duration} />);
}

ProgressChecker.displayName = 'ProgressChecker';

ProgressChecker.propTypes = {
  progress: PropTypes.number.isRequired,
  statusEndpoint: PropTypes.string.isRequired,
};

ProgressChecker.defaultProps = {};

export default ProgressChecker;
