import './tabs.scss';

/**
 * Tabs
 */
const initTabs = () => {
  const tabsElements = document.querySelectorAll('.js-tabs');

  tabsElements.forEach((tabsElement) => {
    const buttonElements = tabsElement.querySelectorAll('.js-tabs-header .js-tabs-header-button');
    const contentElements = tabsElement.querySelectorAll('.js-tabs-content .js-tabs-content-item');

    /**
     * Activates button and relevant content by index
     * @param {Number} index
     */
    const activate = (index) => {
      buttonElements.forEach((buttonElement, buttonIndex) => {
        if (buttonIndex === index) {
          buttonElement.classList.add('active');
        } else {
          buttonElement.classList.remove('active');
        }
      });

      contentElements.forEach((contentElement, contentIndex) => {
        if (contentIndex === index) {
          contentElement.classList.remove('hidden');
        } else {
          contentElement.classList.add('hidden');
        }
      });
    };

    /**
     * Process button click event
     */
    buttonElements.forEach((buttonElement) => {
      buttonElement.addEventListener('click', (event) => {
        const buttons = event.currentTarget.closest('.js-tabs-header').querySelectorAll('.js-tabs-header-button');
        const currentIndex = [...buttons].indexOf(event.currentTarget);
        activate(currentIndex);
      });
    });
  });
};

export default initTabs;
