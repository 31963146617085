const easeInOutQuart = (x) => (x < 0.5 ? 8 * x * x * x * x : 1 - (-2 * x + 2) ** 4 / 2);

const collapse = (el, mode, duration = 300) => {
  const normalHeight = el.scrollHeight;
  let startTime = null;
  let height;

  const animate = (timestamp) => {
    if (!startTime) {
      startTime = timestamp;
    }

    const runtime = timestamp - startTime;
    const relativeProgress = runtime / duration;

    const easedProgress = easeInOutQuart(relativeProgress);

    height = normalHeight * Math.min(easedProgress, 1);

    if (mode === 'down') {
      el.style.setProperty('--height', `${height}px`);

      if (runtime < duration) {
        requestAnimationFrame(animate);
      } else {
        el.style.setProperty('--height', 'auto');
      }
    } else {
      el.style.setProperty('--height', `${normalHeight - height}px`);

      if (runtime < duration) {
        requestAnimationFrame(animate);
      } else {
        el.removeAttribute('style');
      }
    }
  };

  requestAnimationFrame(animate);
};

const initFaq = () => {
  const faqItems = document.querySelectorAll('.faq-item');

  faqItems.forEach((item) => {
    const trigger = item.querySelector('.faq-item-trigger');
    const content = item.querySelector('.faq-item-content');

    const stateClass = '-active';

    trigger.addEventListener('click', () => {
      if (item.classList.contains(stateClass)) {
        collapse(content, 'up');
        item.classList.remove(stateClass);
      } else {
        collapse(content, 'down');
        item.classList.add(stateClass);
      }
    });
  });
};

export default initFaq;
