import SmoothScrollPolyfill from 'smoothscroll-polyfill';
import Rails from '@rails/ujs';
import Component from 'javascripts/component';
import initWindowHeight from 'javascripts/window_height';
import initAnchorScroll from 'javascripts/anchor_scroll';
import initVideoPlayer from 'javascripts/video_player';
import initVideoModal from 'javascripts/video_modal';
import initStoriesSlider from 'javascripts/stories_slider';
import initReviewsSlider from 'javascripts/reviews_slider';
import initSelectize from 'javascripts/selectize';
import initBirthdayInput from 'javascripts/birthday_input';
import initSocialShare from 'javascripts/social_share';
import initFaq from 'javascripts/faq';
import initTabs from 'javascripts/tabs';
import { initAnalyticsObservable, initAnalyticsTrackable } from 'javascripts/analytics';
import OrderFormInputPhoto from 'javascripts/OrderForm/Input/Photo';
import OrderProgressChecker from 'javascripts/OrderProgressChecker';
import OrderSurvey from 'javascripts/OrderSurvey';
import Countdown from 'javascripts/Countdown';
import Contact from 'javascripts/Contact';

/**
* Preload all images
*/
require.context('images', true);

/**
 * Preload all videos
 */
require.context('videos', true);

/**
 * Preload all fonts
 */
require.context('fonts', true);

/**
 * Activate polyfill
 */
SmoothScrollPolyfill.polyfill();

/**
 * Moves viewport to form if it has errors
 */
const moveToOrderFormErrorsIfNeeded = () => {
  if (!document.getElementById('order-form-errors')) return;

  // Move to form if errors occurred
  window.location.hash = '#order-form-errors';
};
moveToOrderFormErrorsIfNeeded();

/**
 * Init rails ujs
 * Init window height
 * Init scrolling to anchors
 * Init video player
 * Init video modals
 * Init stories slider
 * Init reviews slider
 * Init selectize
 * Init birthday input mask
 * Init social share lib
 * Init faq
 * Init tabs
 * Init analytics observable and trackable modules
 */
Rails.start();
initWindowHeight();
initAnchorScroll();
initVideoPlayer();
initVideoModal();
initStoriesSlider();
initReviewsSlider();
initSelectize();
initBirthdayInput();
initSocialShare();
initFaq();
initTabs();
initAnalyticsObservable();
initAnalyticsTrackable();

/**
 * Init react components
 */
Component.initReact('js-order-for-one-photo-component', OrderFormInputPhoto);
Component.initReact('js-order-for-two-photo-component', OrderFormInputPhoto);
Component.initReact('js-order-for-two-additional-photo-component', OrderFormInputPhoto);
Component.initReact('js-order-progress-checker', OrderProgressChecker);
Component.initReact('js-order-survey-options', OrderSurvey, {
  csrfToken: Rails.csrfToken(),
  onSurveyPassed: () => {
    const surveyContainer = document.querySelector('.order-survey-container');
    if (!surveyContainer) return;

    surveyContainer.classList.add('passed');
  },
});
Component.initReact('js-countdown-component', Countdown);
Component.initReact('js-contact-component', Contact);
