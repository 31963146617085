import React, { useRef, useState } from 'react';
import { useRafLoop, usePrevious } from 'react-use';

const PRECISION = 10;

/**
 * Changes the number sequentially
 *
 * @param WrappedComponent
 * @param numberProp
 * @param initialNumber {number}
 * @param defaultDelay {number}
 * @param defaultDuration {number}
 * @returns component that will get buffer instead of property
 */
const withAnimatedNumber = (WrappedComponent, numberProp, initialNumber, defaultDelay, defaultDuration) => (props) => {
  const rawNextNumber = props[numberProp];

  /**
   * Work only with integers
   */
  const nextNumber = Math.floor(rawNextNumber * PRECISION);

  /**
   * If we haven't initial number then use number from props
   */
  initialNumber = initialNumber === undefined ? nextNumber : initialNumber;

  /**
   * If we haven't duration from props then use default duration
   */
  const duration = props.duration === undefined ? defaultDuration : props.duration;

  /**
   * State for current number
   */
  const [currentNumber, setCurrentNumber] = useState(initialNumber);

  /**
   * States for raf updates
   */
  const updateDurationRef = useRef(duration);
  const updateTimestampRef = useRef(null);

  /**
   * When number updates reset animation state
   */
  if (nextNumber !== usePrevious(nextNumber)) {
    updateDurationRef.current = duration;
    updateTimestampRef.current = null;
  }

  useRafLoop((timestamp) => {
    // we get what we want, skip
    if (nextNumber === currentNumber) return;

    // Save timestamp when we start update process
    if (updateTimestampRef.current === null) updateTimestampRef.current = timestamp;

    const passedUpdateDuration = timestamp - updateTimestampRef.current;
    const remainingUpdateDuration = updateDurationRef.current - passedUpdateDuration;

    if (remainingUpdateDuration <= 0) {
      setCurrentNumber(nextNumber);
    } else {
      const step = Math.min(passedUpdateDuration, remainingUpdateDuration) / (passedUpdateDuration + remainingUpdateDuration);
      const delta = step * (nextNumber - currentNumber);

      let newCurrentNumber = Math.floor(currentNumber + delta);
      if (newCurrentNumber > nextNumber) newCurrentNumber = nextNumber;

      setCurrentNumber(newCurrentNumber);
    }
  }, true);

  const newProps = {
    ...props,
    [numberProp]: currentNumber / PRECISION,
  };
  return (<WrappedComponent {...newProps} />);
};

export default withAnimatedNumber;
