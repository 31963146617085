import React, { useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import * as tus from 'tus-js-client';
import './index.scss';

/**
 * Dispatcher actions
 */
const SET_FILE_SOURCE = Object.freeze('SET_FILE_SOURCE');
const INIT_UPLOAD = Object.freeze('INIT_UPLOAD');
const DESTROY_UPLOAD = Object.freeze('DESTROY_UPLOAD');
const SETUP_UPLOAD_STATE_ACTIVE = Object.freeze('SETUP_UPLOAD_STATE_ACTIVE');
const SETUP_UPLOAD_STATE_PAUSED = Object.freeze('SETUP_UPLOAD_STATE_PAUSED');
const SETUP_UPLOAD_STATE_SUCCESS = Object.freeze('SETUP_UPLOAD_STATE_SUCCESS');
const SETUP_UPLOAD_STATE_ERROR = Object.freeze('SETUP_UPLOAD_STATE_ERROR');

/**
 * Upload states
 */
const UPLOAD_STATE_ACTIVE = Object.freeze('ACTIVE');
const UPLOAD_STATE_PAUSED = Object.freeze('PAUSED');
const UPLOAD_STATE_SUCCESS = Object.freeze('SUCCESS');
const UPLOAD_STATE_ERROR = Object.freeze('ERROR');

const initEmptyState = () => ({
  file: null,
  cache: null,
  fileSource: null,
  uploadState: null, // Could be null, ACTIVE, PAUSED, SUCCESS, ERROR
  uploadId: null, // Could be null or String
  uploadError: null, // Could be null or Error
  uploadProgress: null, // Could be null or String representing a number
});

/**
 * @param initState {{cache: String}}
 */
const initState = ({
  file,
  cache,
}) => {
  const emptyState = initEmptyState();

  if (file && cache) {
    return {
      ...emptyState,
      file,
      cache,
      uploadState: UPLOAD_STATE_SUCCESS,
    };
  }

  return emptyState;
};

const reduceState = (state, [type, payload]) => {
  switch (type) {
    case SET_FILE_SOURCE:
      return {
        ...state,
        fileSource: payload,
      };
    case INIT_UPLOAD:
      // Make it virgin, except fileSource
      return {
        ...initEmptyState(),
        fileSource: state.fileSource,
      };
    case DESTROY_UPLOAD:
      // Make it totally virgin
      return initEmptyState();
    case SETUP_UPLOAD_STATE_ACTIVE:
      // Set new state, try to set progress if present
      let newState = {
        ...state,
        uploadState: UPLOAD_STATE_ACTIVE,
      };
      if (payload) {
        newState = {
          ...newState,
          uploadProgress: payload,
        };
      }

      return newState;
    case SETUP_UPLOAD_STATE_PAUSED:
      // Set new state
      return {
        ...state,
        uploadState: UPLOAD_STATE_PAUSED,
      };
    case SETUP_UPLOAD_STATE_SUCCESS:
      // Set new state, set upload id
      return {
        ...state,
        uploadState: UPLOAD_STATE_SUCCESS,
        uploadId: payload,
      };
    case SETUP_UPLOAD_STATE_ERROR:
      // Set new state, set upload error
      return {
        ...state,
        uploadState: UPLOAD_STATE_ERROR,
        uploadError: payload,
      };
    default:
      return state;
  }
};

// custom file input
function Uploader(props) {
  const {
    accept,
    fileName,
    cacheName,
    file,
    cache,
    endpoint,
    chunkSize,
    i18n,
  } = props;

  /**
   * Input parameter name for this uploader component
   * @type {string}
   */
  const uploadFileName = `${fileName}[tus_uid]`;

  /**
   * Reference to tus-js-client Upload object
   */
  const uploadRef = useRef(null);

  /**
   * Component state dispatcher
   */
  const [state, dispatchState] = useReducer(reduceState, {
    file,
    cache,
  }, initState);

  /**
   * Read file as data url and setup it in component state
   * @param file [File, Blob, Reader]
   */
  const analyzeFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      dispatchState([SET_FILE_SOURCE, event.target.result]);
    };
    reader.readAsDataURL(file);
  };

  /**
   * Actions for uploader - init, start, pause, resume, stop
   */

  /**
   * Init uploading session
   * @param file [File, Blob, Reader]
   */
  const initUpload = (file) => {
    const upload = new tus.Upload(file, {
      endpoint,
      chunkSize,
      parallelUploads: 1,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        name: file.name,
        type: file.type,
      },
      // onShouldRetry: (error, retryAttempt, options) => { return true; },
      onError: (error) => {
        // pseudocode example
        // const status = error.originalResponse ? error.originalResponse.getStatus() : null;
        // const message = i18n.t('responseError', {status: status})
        dispatchState([SETUP_UPLOAD_STATE_ERROR, i18n.systemError]);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        // setup percentage
        dispatchState([SETUP_UPLOAD_STATE_ACTIVE, (bytesUploaded / bytesTotal * 100).toFixed(2)]);
      },
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
      },
      onSuccess: () => {
        // File uid at the end of url
        const [, uid] = /\/(\w+)\/?$/.exec(upload.url);
        dispatchState([SETUP_UPLOAD_STATE_SUCCESS, uid]);
      },
    });

    uploadRef.current = upload;
    dispatchState([INIT_UPLOAD]);
  };

  const startUpload = () => {
    if (uploadRef.current) uploadRef.current.start();
    dispatchState([SETUP_UPLOAD_STATE_ACTIVE]);
  };
  const pauseUpload = () => {
    if (uploadRef.current) uploadRef.current.abort(false);
    dispatchState([SETUP_UPLOAD_STATE_PAUSED]);
  };
  const resumeUpload = () => {
    startUpload();
  };
  const destroyUpload = () => {
    if (uploadRef.current) uploadRef.current.abort(true);
    uploadRef.current = null;
    dispatchState([DESTROY_UPLOAD]);
  };

  /**
   * Hidden file input for triggering file select dialog
   */
  const systemFileInputRef = useRef();

  /**
   * Ask user for file
   */
  const promptFile = () => {
    if (systemFileInputRef.current) systemFileInputRef.current.click();
  };

  /**
   * Upload new file
   */
  const repeatUpload = () => {
    destroyUpload();
    promptFile();
  };

  /**
   * When user provides file - analyze it, init upload session and start upload
   *
   * @param event
   */
  const onFileProvided = (event) => {
    const file = event.target.files[0];

    analyzeFile(file);
    initUpload(file);
    startUpload();

    event.target.value = null;
  };

  /**
   * File preview from selected file or cache
   */
  const filePreview = (state.fileSource || (state.cache && state.file))
    ? (
      <img
        className="uploader-preview-img"
        src={state.fileSource || state.file}
        alt="File preview"
        width={200}
        height={200}
      />
    )
    : null;

  /**
   * Views for upload state
   */
  let uploaderView;
  if (state.uploadState === UPLOAD_STATE_ACTIVE) {
    /**
     * View for active state
     * Here we see progress, pause button, cancel button
     */
    uploaderView = (
      <div className="uploader">
        <div className="uploader-inner">
          <div className="uploader-preview" style={{ opacity: state.uploadProgress / 100 }}>
            {filePreview}
          </div>
          <div className="uploader-progress">
            <span className="uploader-progress-title">{i18n.uploadingTitle}</span>
            <span className="uploader-progress-counter">
              {state.uploadProgress}
              %
            </span>
          </div>
          <div className="uploader-actions">
            {/* <div className="uploader-pause" onClick={pauseUpload}>{i18n.pause}</div> */}
            {/* <div className="uploader-cancel" onClick={destroyUpload}>{i18n.cancel}</div> */}
            <div className="uploader-repeat" onClick={repeatUpload}>{i18n.repeatUpload}</div>
          </div>
        </div>
      </div>
    );
  } else if (state.uploadState === UPLOAD_STATE_PAUSED) {
    /**
     * View for paused state
     * Here we see progress, resume button, cancel button
     */
    uploaderView = (
      <div className="uploader">
        <div className="uploader-inner">
          <div className="uploader-preview" style={{ opacity: state.uploadProgress / 100 }}>
            {filePreview}
          </div>
          <div className="uploader-progress">
            <span className="uploader-progress-title">{i18n.uploadingTitle}</span>
            <span className="uploader-progress-counter">
              {state.uploadProgress}
              %
            </span>
          </div>
          <div className="uploader-actions">
            {/* <div className="uploader-resume" onClick={resumeUpload}>{i18n.resume}</div> */}
            {/* <div className="uploader-cancel" onClick={destroyUpload}>{i18n.cancel}</div> */}
            <div className="uploader-repeat" onClick={repeatUpload}>{i18n.repeatUpload}</div>
          </div>
        </div>
      </div>
    );
  } else if (state.uploadState === UPLOAD_STATE_ERROR) {
    /**
     * View for error state
     * Here we see error information, cancel button or/and try again button
     */
    uploaderView = (
      <div className="uploader">
        <div className="uploader-inner">
          <div className="uploader-error">
            <span className="uploader-error-title">{i18n.systemError}</span>
            <span className="uploader-error-description">{i18n.systemErrorDescription}</span>
            {/* {state.uploadError} */}
          </div>
          <div className="uploader-actions">
            {/* <div className="uploader-cancel" onClick={destroyUpload}>{i18n.cancel}</div> */}
            <div className="uploader-repeat" onClick={repeatUpload}>{i18n.upload}</div>
          </div>
        </div>
      </div>
    );
  } else if (state.uploadState === UPLOAD_STATE_SUCCESS) {
    /**
     * View for success state
     * Here we see cancel button
     */
    uploaderView = (
      <div className="uploader">
        <div className="uploader-inner">
          <div className="uploader-preview">
            {filePreview}
          </div>
          <div className="uploader-actions">
            {/* <div className="uploader-cancel" onClick={destroyUpload}>{i18n.cancel}</div> */}
            <div className="uploader-repeat" onClick={repeatUpload}>{i18n.repeatUpload}</div>
          </div>
        </div>
      </div>
    );
  } else {
    /**
     * Default view for selecting file (state.uploadState === null)
     */
    uploaderView = (
      <div className="uploader" onClick={promptFile}>
        <div className="uploader-inner">
          <div className="uploader-icon" />
          <span className="uploader-text">{i18n.upload}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      {uploaderView}
      {state.uploadId && <input type="hidden" name={uploadFileName} value={state.uploadId} />}
      {state.cache && <input type="hidden" name={cacheName} value={state.cache} />}
      <input
        type="file"
        ref={systemFileInputRef}
        accept={accept}
        style={{ display: 'none' }}
        onChange={onFileProvided}
      />
    </>
  );
}

Uploader.displayName = 'Uploader';

Uploader.propTypes = {
  accept: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  cacheName: PropTypes.string.isRequired,
  file: PropTypes.string,
  cache: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  chunkSize: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    upload: PropTypes.string,
    repeatUpload: PropTypes.string,
    uploadingTitle: PropTypes.string,
    pause: PropTypes.string,
    resume: PropTypes.string,
    cancel: PropTypes.string,
    systemError: PropTypes.string,
    systemErrorDescription: PropTypes.string,
  }).isRequired,
};

Uploader.defaultProps = {
  accept: '*/*',
};

export default Uploader;
