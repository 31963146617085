import React from 'react';
import PropTypes from 'prop-types';
import * as tus from 'tus-js-client';
import Uploader from './Uploader';
import Fallback from './Fallback';

// custom file input
const Photo = (props) => {
  const {
    accept,
    fileName,
    cacheName,
    file,
    cache,
    endpoint,
    chunkSize,
    i18n,
  } = props;

  // Fallback for super old browsers
  if (tus.isSupported) {
    return (
      <Uploader
        accept={accept}
        fileName={fileName}
        cacheName={cacheName}
        file={file}
        cache={cache}
        endpoint={endpoint}
        chunkSize={chunkSize}
        i18n={i18n}
      />
    );
  }

  return (
    <Fallback
      accept={accept}
      fileName={fileName}
      cacheName={cacheName}
      cache={cache}
      label={i18n.upload}
    />
  );
};

Photo.displayName = 'Photo';

Photo.propTypes = {
  accept: PropTypes.string,
  fileName: PropTypes.string.isRequired,
  cacheName: PropTypes.string.isRequired,
  file: PropTypes.string,
  cache: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  chunkSize: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    upload: PropTypes.string,
    repeatUpload: PropTypes.string,
    uploadingTitle: PropTypes.string,
    pause: PropTypes.string,
    resume: PropTypes.string,
    cancel: PropTypes.string,
    systemError: PropTypes.string,
    systemErrorDescription: PropTypes.string,
  }).isRequired,
};

Photo.defaultProps = {
  accept: '*/*',
};

export default Photo;
