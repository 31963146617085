import Inputmask from 'inputmask';

const initBirthdayInput = () => {
  const inputs = document.querySelectorAll('.js-input-birthday');
  const inputmask = new Inputmask('datetime', {
    inputFormat: 'dd.mm.yyyy',
    placeholder: 'dd.mm.yyyy',
  });

  inputmask.mask(inputs);
};

export default initBirthdayInput;
