const initWindowHeight = () => {
  const setWindowHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.querySelector(':root').style.setProperty('--vh', `${vh}px`);
  };

  setWindowHeight();

  window.addEventListener('resize', () => {
    setWindowHeight();
  });
};

export default initWindowHeight;
