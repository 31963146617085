import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const initStoriesSlider = () => {
  new Swiper('.stories-slider', {
    modules: [Navigation, Pagination],
    pagination: {
      el: '.slider-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.slider-button.-next',
      prevEl: '.slider-button.-prev',
    },
    simulateTouch: false,
    spaceBetween: 16,
    slidesPerView: 'auto',
    centeredSlides: true,
    watchSlidesProgress: true,
    loop: true,
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 3,
        centeredSlides: false,
      },
    },
    on: {
      slideChangeTransitionEnd: (swiper) => {
        swiper.update();
      },
    },
  });
};

export default initStoriesSlider;
