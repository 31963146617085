import $ from 'jquery';
import 'selectize';
// todo drop jquery dependency

const scrollTo = ($element, offset = 120, duration = 800) => {
  const top = $element.offset().top - offset;
  $('body,html').animate({ scrollTop: top }, duration);
};

const scrollToLabel = (input, offset = 12, duration = 400) => {
  const label = $(input).closest('.upload-form__item').find('.upload-form__label');
  scrollTo(label, offset, duration);
};

const initSelectize = () => {
  const selectizeSelects = $('.js-select-input').selectize();

  const isMobileDevice = window.matchMedia('(max-width:600px)').matches;
  if (!isMobileDevice) return;

  /**
   * Hacks for mobile devices
   */
  $('.js-input').on('focus', (event) => {
    scrollToLabel(event.target);
  });

  if (!selectizeSelects) return;

  selectizeSelects.each((index, selectizeSelect) => {
    if ($(selectizeSelect).data('selectize-readonly')) {
      selectizeSelect.selectize.$control_input.prop('readonly', true);
    }

    const that = selectizeSelect;
    selectizeSelect.selectize.on('focus', () => {
      scrollToLabel(that);
    });
  });
};

export default initSelectize;
